#bottom {
  height: 50;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border: "1px solid black";
}

#base{
  direction: rtl;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 auto;
}

#gridColumn {
  width: 10px;
  display: grid;
  grid-template-columns: 10px fit-content(20%);
}

#button {
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
}

#firstRow {
  position: relative;
  display: flex;
  height: 80%;
  width: 100%;
  margin: 5px auto;
}

#secondRow {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 20% !important;
  width: 100%;
  margin: 5px auto;
}

#input {
  text-align: center;
  direction: rtl;
  justify-content: center;
  text-align: center;
  align-items: center;
}

#checkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: '10px';    
}
/* .hashem2{
  /* margin: 20px auto; */
  /* padding: 20px; */
  /* width: 100%;
  direction: rtl;
  position: absolute;
  display: block;
  justify-content: center;
  align-items: center;
} */ 

body, html,#root, #container{
  height: 100%!important;
  width: 100%!important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.label{
  margin: 5px auto!important;
}

#base{
  direction: rtl;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 auto;
}

.P-Message{
  position: absolute;
  border-radius: 5px;
  display: table;
  margin:0 auto;
  /* top: 100px; */
  /* z-index: 5; */
  background-color: rgba(30, 255, 0, 0.5);
}


#column1,#column2{
  display: block!important;
  text-align: center!important;
}

#input1,#input2{
  text-align: center;
  direction: rtl;
}

.gameteory-rentroom-game .center{
  display: table;
  margin:0 auto;
}

#row1{
  position: relative;
  display: flex;
  height: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 162, 255, 0.76)
}

#row2{
  position: relative;
  bottom: 0;
  display: block;
  /* justify-content: center; */
  /* text-align: center; */
  /* align-items: center; */
  height: 20%!important;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(30, 255, 0, 0.5)
}
#base10{
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 auto!important;
  padding: 0 auto!important;
}

#blocks{
  direction: rtl;
  text-align: right;
  overflow-x: scroll;
}

#backGround{
  background-color: blue;
}
#grid {
  display: inline-grid;
  grid-template-columns: auto auto;
}

#gridColumn {
  border: double black;
}

#container {
  white-space: nowrap;
}

#popup {
  direction: rtl;
}

#line {
  font-size: smaller;
  display: 'inline-flex';
  vertical-align: 'middle';
  display: 'table';
  border: double black;
}

#blankBox {
  font-size: medium;
  font-weight: bold;
  cursor: 'move';
  padding: 3px;
}

#answerBox {
  font-size: medium;
  padding: '5px';
  font-weight: bold;
  cursor: 'move';
  margin-bottom: 2px;
}

.ui.header.first-page-header {
  text-align: center;
  font-weight: 800;
}

.question-file-continer {
  position: absolute;
  bottom: 50px;
  right: 200px;
  z-index: 100;
  direction: rtl;
  text-align: center;
}

.question-file-continer .question-btn span {
  display: inline-flex;
}

.question-file-continer .question-btn,
.question-file-continer .payment-btn,
.question-file-continer .ui.button.landing-answer-btn {
  font-size: 28px;
  font-weight: 800;
  width: 340px;
  text-align: center;
  margin: 5px;
}

.question-file-continer .question-btn span,
.question-file-continer .payment-btn span,
.question-file-continer .ui.button.landing-answer-bt span {
  text-align: center;
}

.question-file-continer .arrow-right {
  position: absolute;
  top: -70px;
  right: -40px;
}

.question-file-continer .arrow-left {
  position: absolute;
  top: -70px;
  left: -40px;
}

.question-file-continer .arrow-pic img {
  width: 80px;
  height: 50px;
  -webkit-filter: grayscale(100%) brightness(200%);
  filter: grayscale(100%) brightness(200%);
}

.question-file-continer .question-btn,
.question-file-continer .payment-btn {
  background: #35be31;
  border: 1px solid #999;
  line-height: 25px;
  padding: 15px 25px;
  color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
  text-align: center;
  margin: 3px auto;
  transition: 0.5s;
  display: flex;
  align-content: center;
  justify-content: center;
}

.question-file-continer .payment-btn {
  background: #4183c4;
}

.question-file-continer .question-btn:hover,
.question-file-continer .question-btn:focus,
.question-file-continer .payment-btn:hover,
.question-file-continer .payment-btn:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}
.rotate-right {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: rotate-right;
  animation-name: rotate-right;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.rotate-left {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: rotate-left;
  animation-name: rotate-left;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: right bottom;
  transform-origin: left bottom;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate__bounceIn {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.landing-first {
  height: 100vh;
  height: calc(100vh - 5em);
  display: flex;
  align-items: center;
  padding-bottom: 80px;
  position: relative;
}

.landing-background {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(#feceab33, #feceab33),
    url(/static/media/back.f979487a.jpg) no-repeat center center fixed;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  opacity: 0.6;
  background-size: cover;
  z-index: -1;
  -webkit-animation: show-back 0.8s 0.3s both;
          animation: show-back 0.8s 0.3s both;
}

.landing-back {
  background: #ff847c;
}

.ui.grid {
  margin: 0;
}

.first-header {
  text-align: center;
  margin: auto;
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
  color: #555;
  text-shadow: 3px 3px #888;
  position: relative;
  max-width: calc(100% - 100px);
}

@media (max-width: 900px) {
  .first-header {
    font-size: 60px;
  }
}

.first-footer-image {
  position: absolute;
  bottom: 10px;
  left: 10px;
  -webkit-filter: drop-shadow(3px 3px 5px #33333333);
          filter: drop-shadow(3px 3px 5px #33333333);
}

.first-footer-image img {
  width: 160px;
  /* clip-path: url(#myClip);
  -webkit-clip-path: url(#myClip); */
}

@media (max-width: 600px) {
  .first-footer-image {
    display: none;
  }
  .question-file-continer {
    left: 30px;
    bottom: 10px;
    width: 40%;
  }
  .question-file-continer .question-btn,
  .question-file-continer .payment-btn,
  .question-file-continer .ui.button.landing-answer-btn {
    font-size: 20px;
    width: 100%;
    margin: 5px;
    padding: 8px 5px;
  }

  .question-file-continer .arrow-right {
    position: absolute;
    top: -30px;
    right: -20px;
  }

  .question-file-continer .arrow-left {
    position: absolute;
    top: -30px;
    left: -20px;
  }

  .question-file-continer .arrow-pic img {
    width: 40px;
    height: 25px;
  }
  .question-file-continer .question-btn span.mobile-hide {
    display: none;
  }
}

.sun-pic {
  position: absolute;
  top: -55px;
  left: -35px;
  -webkit-filter: drop-shadow(3px 3px 5px #33333333);
          filter: drop-shadow(3px 3px 5px #33333333);
}
.sun-pic img {
  width: 80px;
  -webkit-animation: logo_vib 2s infinite;
          animation: logo_vib 2s infinite;
}

.fruit-pic {
  position: absolute;
  top: -55px;
  right: -35px;
  -webkit-filter: drop-shadow(3px 3px 5px #33333333);
          filter: drop-shadow(3px 3px 5px #33333333);
}
.fruit-pic img {
  width: 80px;
}

.scroll-pic {
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  -webkit-filter: drop-shadow(3px 3px 5px #33333333);
          filter: drop-shadow(3px 3px 5px #33333333);
  width: 100%;
}
.scroll-pic img {
  width: 30px;
  display: block;
  margin: auto;
}

@-webkit-keyframes logo_vib {
  0% {
    -webkit-transform: scale(1) rotate(-20deg);
            transform: scale(1) rotate(-20deg);
  }

  30% {
    -webkit-transform: scale(1.01) rotate(-8deg);
            transform: scale(1.01) rotate(-8deg);
  }

  70% {
    -webkit-transform: scale(1.05) rotate(-30deg);
            transform: scale(1.05) rotate(-30deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(-20deg);
            transform: scale(1) rotate(-20deg);
  }
}

@keyframes logo_vib {
  0% {
    -webkit-transform: scale(1) rotate(-20deg);
            transform: scale(1) rotate(-20deg);
  }

  30% {
    -webkit-transform: scale(1.01) rotate(-8deg);
            transform: scale(1.01) rotate(-8deg);
  }

  70% {
    -webkit-transform: scale(1.05) rotate(-30deg);
            transform: scale(1.05) rotate(-30deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(-20deg);
            transform: scale(1) rotate(-20deg);
  }
}

.second-page {
  padding: 30px 10px;
  direction: rtl;
  text-align: right;
  background-color: #99b898;
  color: white;
}

.second-2-page {
  direction: rtl;
  text-align: right;
  background-color: #ededed;
  color: black;
}

.third-page {
  padding: 30px 10px;
  direction: rtl;
  text-align: right;
  background-color: #e84a5f;
  color: white;
}

.third-2-page {
  padding: 30px 10px;
  direction: rtl;
  text-align: right;
  background-color: #feceab;
}

.fourth-page {
  padding: 30px 10px;
  direction: rtl;
  text-align: right;
  background-color: #ededed;
  color: #333333;
}

.fifth-page {
  padding: 30px 10px;
  direction: rtl;
  text-align: right;
  background-color: #99b898;
  color: #333333;
}

.what-pic {
  margin: auto;
}

.what-pic img {
  display: block;
  max-height: 300px;
  margin: 0 auto 30px;
  border: 10px solid #ccc;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes show-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
@keyframes show-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
@-webkit-keyframes rotate-right {
  0% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
}
@keyframes rotate-right {
  0% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
}
@-webkit-keyframes rotate-left {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
@keyframes rotate-left {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}

.h_iframe-aparat_embed_frame {
  position: relative;
  margin: 0 auto 30px;
}
.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.ui.button.landing-answer-btn {
  display: block;
  margin-top: 30px;
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  direction: rtl;
}

.ui.button.landing-answer-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.25);
}

.first-page-center-btns a {
  color: #eaeaea;
}
.first-page-center-btns span {
  display: inline-block;
  border: 1px solid #35be32;
  margin: 30px 10px 0;
  padding: 8px 20px 15px;
  border-radius: 10px;
  transition: 0.3s;
  background: #35be3299;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.25);
}

.first-page-center-btns span.login-btn {
  display: inline-block;
  border: 1px solid #2185d0;
  background: #2185d099;
  margin: 30px 10px 0;
  border-radius: 10px;
  transition: 0.3s;
}
.first-page-center-btns span:hover,
.first-page-center-btns span:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.25);
}

.end-of-registration {
  position: absolute;
  top: 30px;
  left: 40px;
  direction: rtl;
  border: 3px solid #e84a5f;
  color: #e84a5f;
  z-index: 100;
  font-size: 25px;
  line-height: 25px;
  padding: 10px 30px;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  background: #ffffff77;
  text-shadow: 1px 1px #888;
}

@media (max-width: 600px) {
  .first-page-center-btns span {
    font-size: 30px;
    padding: 0 20px;
    line-height: 50px;
  }
  .end-of-registration {
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 5px;
  }
}

body.iranyekan h1.ui.header {
  font-size: 2.8rem;
  font-weight: 800;
}

body {
  background: #f7f9fc;
}

* div {
  font-family: iranyekan !important;
}

.rtl,
.ui.input.rtl > input {
  text-align: right;
  direction: rtl;
}

.ltr-input > input {
  direction: ltr !important;
}

.form-label {
  width: 100%;
  display: block;
  text-align: right;
}
.tox.tox-tinymce {
  direction: rtl !important;
}
.tox.tox-tinymce,
.ui.input > input,
.ui.input > textarea {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35), 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: 3px 0;
  direction: rtl;
  border: none;
  transition: 0.3s;
}

.tox.tox-tinymce:focus,
.tox.tox-tinymce:hover,
.ui.input > input:focus,
.ui.input > input:hover,
.ui.input > textarea:focus,
.ui.input > textarea:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35), 0 4px 8px rgba(0, 0, 0, 0.05);
}

.centered {
  margin: 0 auto !important;
}

.tox-notifications-container {
  display: none;
}

.tox-statusbar {
  border: none !important;
  direction: ltr !important;
}

.tox-statusbar a {
  display: none;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  direction: ltr;
}

.tox .tox-tbtn--bespoke[title='اندازه فونت'] .tox-tbtn__select-label {
  width: 35px;
}

.tox .tox-tbtn--bespoke[title='فونت‌ها'] .tox-tbtn__select-label {
  width: 50px;
}

.tox .tox-tbtn--bespoke[title='Formats'] .tox-tbtn__select-label {
  width: 50px;
}

.tox .tox-dialog {
  direction: rtl;
}

.ui.message .header {
  font-family: iranyekan;
}

.persian-input .ui.input > input::-webkit-input-placeholder {
  text-align: right;
}

.persian-input .ui.input > input::placeholder {
  text-align: right;
}

h1.ui.header {
  font-weight: 800;
}

h2.ui.header {
  font-weight: 600;
}

h3.ui.header {
  font-weight: 500;
}

.ui.label.selectable-tag {
  -webkit-filter: drop-shadow(0px 1px 2px #333333);
          filter: drop-shadow(0px 1px 2px #333333);
  transition: 0.3;
}

.ui.label.selectable-tag:hover {
  background: rgb(72, 116, 235);
  color: white;
}

.ui.label.selectable-tag::before {
  background: #f3f3f3;
  border-radius: 3px;
}

.ui.label.selectable-tag.selected::before,
.ui.label.selectable-tag.selected {
  background: rgb(53, 98, 223);
  color: white;
}

.ui.label.selectable-tag:hover,
.ui.label.selectable-tag:focus {
  background: rgb(72, 116, 235);
  cursor: pointer;
}

.ui.label.selectable-tag.selected:hover::before,
.ui.label.selectable-tag:hover::before,
.ui.label.selectable-tag.selected:focus::before,
.ui.label.selectable-tag:focus::before {
  background: rgb(72, 116, 235);
}

.ui.label.selectable-tag::before,
.ui.label.selectable-tag {
  background: #fafafa;
}

.ui.label.selectable-tag.not-selected {
  border: 1px solid #ccc;
}

.ui.label.selectable-tag.not-selected::before {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.ui.menu .ui.dropdown .menu > .item .icon:not(.dropdown) {
  margin: 0px !important;
}

.ui.sidebar {
  overflow: visible !important;
}
.workshop-content .embed,
.workshop-content video {
  border-radius: 10px;
  border: 3px solid #676966;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35), 0 4px 8px rgba(0, 0, 0, 0.05),
    inset 2px 6px rgba(0, 0, 0, 0.35);
  outline: none;
  width: 100%;
}

.mentor-modal *,
.workshop-form .ui.form *,
.workshop-content * {
  text-align: right;
  direction: rtl;
}

.workshop-content .ui.celled.table tr td,
.workshop-content .ui.celled.table tr th {
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  text-align: center;
}

.workshop-form .ui.form input,
.workshop-form .ui.form textarea {
  margin: 5px auto 10px;
}

.workshop-form .ui.button {
  margin: 0;
  text-align: center;
}

.whiteboard.ui.modal {
  position: relative;
  display: inline-block;
  padding: 8px;
  margin: 50px auto;
  background: #ccc;
  border-radius: 10px;
  box-shadow: 0 3px #bbb, 0 6px #aaa, 0 -3px #bbb, 0 -6px #aaa, 3px 0 #bbb,
    6px 0 #aaa, -3px 0 #bbb, -6px 0 #aaa;
  touch-action: none;
}

.whiteboard.ui.modal:before,
.whiteboard.ui.modal:after,
.whiteboard.ui.modal > i:before,
.whiteboard.ui.modal > i:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -6px;
  width: 0;
  height: 0;
  border: 17px solid transparent;
  border-top-color: #979797;
  border-left-color: #979797;
  border-radius: 10px 0 0;
  -webkit-filter: drop-shadow(2px 2px #aaaaaa) drop-shadow(2px 2px #bbbbbb);
}

.whiteboard.ui.modal:after {
  left: auto;
  right: -6px;
  border: 17px solid transparent;
  border-top-color: #979797;
  border-right-color: #979797;
  border-radius: 0 10px 0;
  -webkit-filter: drop-shadow(-2px 2px #aaaaaa) drop-shadow(-2px 2px #bbbbbb);
}

.whiteboard.ui.modal > i:before {
  top: auto;
  bottom: -6px;
  border: 17px solid transparent;
  border-bottom-color: #979797;
  border-left-color: #979797;
  border-radius: 0 0 0 10px;
  -webkit-filter: drop-shadow(2px -2px #aaaaaa) drop-shadow(2px -2px #bbbbbb);
}

.whiteboard.ui.modal > i:after {
  top: auto;
  left: auto;
  bottom: -6px;
  right: -6px;
  border: 17px solid transparent;
  border-bottom-color: #979797;
  border-right-color: #979797;
  border-radius: 0 0 10px;
  -webkit-filter: drop-shadow(-2px -2px #aaaaaa) drop-shadow(-2px -2px #bbbbbb);
}

.whiteboard.ui.modal > .content {
  padding: 0 !important;
}

.whiteboard-nav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  padding: 10px 15px;
  z-index: 10;
  display: flex;
  pointer-events: none;
}

.whiteboard-nav ul {
  background-color: #777;
  border-radius: 5px;
  padding: 3px;
  list-style: none;
  margin: 5px;
  pointer-events: fill;
  pointer-events: visibleFill;
}

.whiteboard-nav li {
  float: left;
  margin: 2px;
  color: white;
  text-shadow: 1px 1px #000;
}

.whiteboard-nav i.icon {
  margin: 0;
}

.whiteboard-nav li:hover,
.whiteboard-nav li:focus {
  cursor: pointer;
  text-shadow: 2px 2px #000;
  color: #caebfa;
}

.whiteboard-nav i.selected {
  color: #94dafa;
}

.whiteboard-nav i.disable {
  color: #ccc;
  text-shadow: none;
}

.whiteboard-nav i.disable:hover,
.whiteboard-nav i.disable:focus {
  color: #ccc;
  text-shadow: none;
  cursor: default;
}

.ui.menu.fixed {
  z-index: 1001;
}

.nav .ui.menu {
  transition: 0.3s;
}

.no-nav-back .no-scroll .ui.menu {
  background: none;
  border: none;
  box-shadow: none;
  padding-top: 30px;
  padding-bottom: 30px;
}

.no-nav-back .ui.menu.sidebar {
  background: white;
}

.padded-nav .ui.menu {
  padding: 0 5%;
}

.under-nav-items {
  min-height: 100vh;
  padding-top: 5em;
  display: grid;
}

.pushable:not(body) {
  -webkit-transform: none;
          transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.no-nav-back .logo-size.ui.mini {
  width: 55px;
  height: 55px;
}

@media (max-width: 600px) {
  .no-nav-back .logo-size.ui.mini {
    width: 45px;
    height: 45px;
  }
}

.rtl-input .ui.input > input,
.rtl-input .ui.input > textarea,
.rtl-input .ui.selection.dropdown .menu > .item {
  direction: rtl;
  text-align: right;
}

.ltr-input .ui.input > input,
.ltr-input .ui.input > textarea,
.ltr-input .ui.selection.dropdown .menu > .item {
  direction: ltr;
  text-align: left;
}

.rtl-placeholder .ui.input > input::-webkit-input-placeholder, .rtl-placeholder .ui.input > textarea::-webkit-input-placeholder {
  direction: rtl;
  text-align: right;
}

.rtl-placeholder .ui.input > input::placeholder,
.rtl-placeholder .ui.input > textarea::placeholder,
.rtl-placeholder .ui.selection.dropdown {
  direction: rtl;
  text-align: right;
}

.ltr-placeholder .ui.input > input::-webkit-input-placeholder, .ltr-placeholder .ui.input > textarea::-webkit-input-placeholder {
  direction: ltr;
  text-align: left;
}

.ltr-placeholder .ui.input > input::placeholder,
.ltr-placeholder .ui.input > textarea::placeholder,
.ltr-placeholder .ui.selection.dropdown {
  direction: ltr;
  text-align: left;
}

@media (max-width: 700px) {
  .ui.form .fields .wide.field {
    margin: 0 0 5px;
  }
}

.auth-back {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background: linear-gradient(#a5a8a299, #a5a8a299),
    url('https://thumbs.dreamstime.com/b/seamless-math-background-9185284.jpg'); */
  filter: blur(4px);
  -webkit-filter: blur(4px);
  background-size: 500px 500px;

  z-index: -1;
}

.ui.button {
  margin: 0;
}

select:required:invalid {
  color: #ccc;
}
option[value=''][disabled] {
  display: none;
}
option {
  color: #000;
}

.gender-icons {
  min-width: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.gender-icon {
  background: #ccc;
  border-radius: 3px;
  padding: 3px 2px 0;
  border: 1px solid #eee;
  margin: auto;
  width: 30px;
}

.gender-icon:hover,
.gender-icon:focus {
  background: #94dafa;
}

.gender-icon.selected {
  background: #84cf82;
}

.gender-icons img {
  width: 15px;
}

.ui.menu .item {
  padding: 5px;
}

.close-message-btn {
  position: absolute;
  top: 3px;
  right: 3px;
  color: red;
}

.close-message-btn:hover,
.close-message-btn:focus {
  cursor: pointer;
}

.payment-card.ui.segment {
  max-width: 500px;
  margin: 50px auto;
  direction: rtl;
  padding: 30px;
}

.payment-card.payment-card-success {
  background: #35be32;
}

.payment-card.payment-card-failure {
  background: #e84a5f;
}

.payment-card h1.ui.header {
  text-shadow: 2px 2px #aaa;
}

.payment-card.payment-card-success h1.ui.header,
.payment-card.payment-card-failure h1.ui.header {
  color: white;
}

.payment-card .ui.segment {
  color: #333;
}

@media only screen and (max-width: 500px) {
  .workshop-page .ui.grid > .column {
    padding: 0;
  }
  .workshop-page.ui.container {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }
  .workshop-content .embed,
  .workshop-content video {
    border: 2px solid #676966;
  }
}

.ui.dimmer {
  z-index: 10000;
}

.tox .tox-dialog-wrap,
.tox-tinymce-aux {
  z-index: 10001;
}

.jit-si-meet-container {
  overflow: hidden;
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 300px;
  width: 510px;
  z-index: 10000;
  background: #ccc;
  border-radius: 8px;
  border: 1px solid #999;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35), 0 4px 8px rgba(0, 0, 0, 0.05);
}
.jit-si-meet-container iframe {
  height: 300px;
}
.jit-si-meet-container.hidden-jitsi {
  height: 0;
  width: 0;
  border: none;
}

.exit-jit-si-meet-container {
  position: absolute;
  left: 8px;
  top: 8px;
}

.reload-jit-si-meet-container {
  position: absolute;
  left: 40px;
  top: 8px;
}

@media (max-width: 500px) {
  .jit-si-meet-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
  }
  .jit-si-meet-container iframe {
    height: 100vh;
  }
}

.widget-view-container {
  position: relative;
}

.widget-view-icons {
  position: absolute;
  z-index: 5;
  top: -10px;
  right: -20px;
  font-size: 30px;
  color: #94dafa;
}

.widget-delete-icons,
.widget-edit-icons {
  display: inline-block;
}

.widget-delete-icons {
  color: red;
}

.ui.pointing.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

.userFirstCharName {
  width: 25px;
  height: 25px;
  line-height: 20px;
  font-weight: 800;
  border-radius: 50%;
  margin: 5px;
  color: white;
  text-align: center;
}

@media (max-width: 500px) {
  .ui.menu:not(.vertical) .item > .button.mentorRequest {
    font-size: 11px;
  }

  .userFirstCharName {
    font-size: 11px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    margin: 4px;
  }
}

.help-workshop-btn {
  background: #35be32;
  padding: 10px 20px;
  transition: 0.3s;
  margin: 6px;
  color: white;
}

.help-workshop-btn :hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35), 0 4px 8px rgba(0, 0, 0, 0.05);
}

.new-server-background {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(#feceab33, #feceab33),
    url(/static/media/cloud.71b352f1.jpg) no-repeat center center fixed;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  opacity: 0.6;
  background-size: cover;
  z-index: -1;
  -webkit-animation: show-back 0.8s 0.3s both;
          animation: show-back 0.8s 0.3s both;
}

.new-server-center-items {
  direction: rtl;
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  color: #0978d3;
  font-weight: 900;
  margin: auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  text-shadow: 2px 2px #bbb;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 400) {
  .new-server-center-items {
    font-size: 20px;
  }
}

.ui.attached.tabular.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

